import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Link } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logoImg from '../../assets/images/vehicles/logo.png';
import { useAuthContext } from '../../auth/useAuthContext';
import findFirstAuthorizedItem from '../../utils/findFirstAuthorizedItem';
import { useNavConfig } from '../nav-section/vertical/config-navigation';
const Logo = forwardRef(({ disabledLink = false, isRegister = false, sx, ...other }, ref) => {
    const { user } = useAuthContext();
    const navConfig = useNavConfig();
    const PATH_AFTER_LOGIN = findFirstAuthorizedItem(navConfig[0]?.items, user?.permissions, user?.role?.code, user?.active, user?.email);
    const logo = (_jsx(Box, { ref: ref, component: "div", sx: {
            height: 46,
            display: 'inline-flex',
            ...sx,
        }, ...other, children: _jsx("img", { src: logoImg, alt: "logo" }) }));
    if (disabledLink) {
        return logo;
    }
    return (_jsx(Link, { component: RouterLink, to: PATH_AFTER_LOGIN.includes('/login') ? '/' : PATH_AFTER_LOGIN, sx: { display: 'contents' }, children: logo }));
});
export default Logo;
